import React, {useEffect, useState} from 'react';
import '../assets/css/SelectPackage.css';
import '../assets/css/PricesPage.css';
import PriceCards from "../components/PriceCards";
import {Link} from "react-router-dom";
import {getNewUser, getUser} from "../auth/Auth";
import Loading from "../components/Loading";
import logo from "../assets/images/logo-dark.svg";

function SelectPackage() {
    const [newUser, setNewUser] = useState(null);

    useEffect(() => {
        getNewUser().then((data) => {
            setNewUser(data);
            if (!data) {
                getUser(false).then((data) => {
                    setNewUser(data === undefined ? null : data);
                }).catch(() => {
                    setNewUser(null);
                });
            }
        }).catch(() => {
            setNewUser(null);
        });
    }, []);

    if (newUser) {
        return (
            <>
                <div className='body-main  body-main-package slt-pck-container '>
                    <div className="slt-pck-container left-slt-pck-div ">
                        <div className='select-pkg-logo-main'>
                            <a href="/">
                                <img src={logo} alt='Logo'/>
                            </a>
                        </div>
                        <div>
                            <div className='select-packaguuue-price-cardy'>
                                <div>
                                    <PriceCards/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group-one-bottom">
                            <label htmlFor="terms">
                                You can cancel your plan at any time. Purchases made by credit card can’t be refunded,
                                after refunded to a your credit can be transferred to another account.<br/>
                                By submitting this form, you confirm that you agree to our <Link to="/legal">Terms of
                                Service</Link> and <Link to="/legal">Privacy Policy</Link>.
                            </label>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <Loading/>
        );
    }
}

export default SelectPackage;
