import React, {useEffect, useState} from 'react'
import '../assets/css/AboutUs.css';
import '../assets/css/Home.css';
import '../assets/css/Series.css';
import image10 from "../assets/images/image10.png";
import image11 from "../assets/images/image11.png";
import tickcircle from "../assets/images/tickcircle.svg";
import aboutusbanner from "../assets/images/aboutusbanner.jpeg";
import {loadCTABanner} from "../common/Common";
import Loading from "../components/Loading";
import CTABanner from "../components/CTABanner";

export default function AboutUs() {
    const [ctaBanner, setCtaBanner] = useState(null),
        [loading, setLoading] = useState(true);

    useEffect(() => {
        loadCTABanner("about us").then((data) => {
            setCtaBanner(data);
            setLoading(false);
        }).catch(() => null);
    }, []);

    if (!loading) {
        return (
            <div className='contact-main body-main'>
                <div className='contact-container'>
                    <div className='top-banner-default'>
                        <div className='text-section series-container'>
                        <span className='bdc-txt '>
                            Home / About
                        </span>
                            <span href="" className='mani-pg-name'>About</span>
                        </div>
                        <div className='abs-slider-images'>
                            <picture>
                                <source media="(max-width: 767px)" srcset={aboutusbanner} alt="/"/>
                                <source media="(max-width: 1023px)" srcset={aboutusbanner} alt=""/>
                                <img src={aboutusbanner} alt="background"/>
                            </picture>
                        </div>
                    </div>

                    <div className='section-2-about'>
                        <div className='container conent-gap'>
                            <div className='section-2-left'>
                                <span className='topic'>Meet Your Instructor,<br/> Aaron McDermott</span>
                            </div>
                            <div className='section-2-right'>
                                <span className='description'>“Hi everyone! I’m Aaron and I’ll be your instructor during your Pilates journey. It’s lovely to meet you and I hope you enjoy and gain value from your time here at The Pilates Corner.”</span>
                            </div>
                        </div>
                    </div>

                    <div className='section-3-about'>
                        <div className='container'>
                            <div className='section-3-about-image'>
                                <img src={image10} alt='image10'/>
                            </div>
                            <div>
                                <div className='section-3-topic'>
                                    <h1>A word from your Instructor</h1>
                                </div>
                                <div className='section-3-description'>
                                    <span>My journey with Pilates started shortly after I graduated from my Physiotherapy degree at University. I have always been hugely interested in exercise and sports, playing several growing up. The obvious choice when it came time to choose a career was  Physiotherapy. I completed my undergraduate and later an MSc in Sports and Exercise Medicine at the University of Ulster. </span><br></br>
                                    <span>I am lucky enough to currently work in private practice and professional soccer in Ireland. I feel this gives me a really acute sense of the benefits of movement and exercise on a person’s health, happiness and overall quality of life! I first studied and started teaching Pilates as part of my clinical hours in one of my first roles after graduating. When I began to see the beneficial effects it was having for my patients and class members I never looked back!</span>
                                </div>
                                {/* <button className='section-3-about-button'>Read More</button> */}
                            </div>
                        </div>
                    </div>
                    <div className='about-4spc-wrapper home-container about-page'>
                        <div className='section-4-about series-container inner-cont' >
                            <div className='section-4-topic-list list-div'>
                                <div className='section-4-topic list-div'>
                                    <h5 style={{fontWeight:'400'}}>Discover the benefits</h5>
                                </div>
                                <div className='section-4-list '>
                                <span>  <img src={tickcircle}
                                             alt='image11'/>60+ class gallery with 2 new classes added each week</span><br></br>
                                    <span><img src={tickcircle} alt='image11'/><div>Several distinct class levels - Beginner Baby Steps (<svg width="9" height="8" xmlns="http://www.w3.org/2000/svg"><circle cx="4" cy="4" r="4" fill="#00ff00" /></svg>), Modifier (<svg width="9" height="8" xmlns="http://www.w3.org/2000/svg"><circle cx="4" cy="4" r="4" fill="blue" /></svg>), Sweat (<svg width="9" height="8" xmlns="http://www.w3.org/2000/svg"><circle cx="4" cy="4" r="4" fill="red" /></svg>) and Equipment (<svg width="9" height="8" xmlns="http://www.w3.org/2000/svg"><circle cx="4" cy="4" r="4" fill="#000" /></svg>).</div></span><br></br>
                                    <span><img src={tickcircle} alt='image11'/>Flexible class lengths - A catalogue of different class lengths ranging from 10mins to 45mins+</span><br></br>
                                    <span><img src={tickcircle} alt='image11'/>24hr access - Enjoy the freedom to practice Pilates anytime anywhere with 24hr access to your account</span><br></br>
                                    <span><img src={tickcircle} alt='image11'/>Control over your exercise routine  - Your Pilates class schedule will now always suit your weekly schedule, allowing you to be consistent and see meaningful change.</span><br></br>
                                </div>
                            </div>
                            <div className='child-inner-div'>
                                <img src={image11} alt='image11'/>
                            </div>
                        </div>
                    </div>
                    <div className='section-5-about'>
                        <div className='container'>
                            <div className='vision-mission-main'>
                                <div className='vision-main'>
                                    <div>
                                        <span className='vision'>Vision</span>
                                        <hr/>
                                    </div>
                                    <div>
                                        <span className='vision-details'>To create a supportive community where people can discover the benefits of Pilates, connect with like-minded individuals, and embark on a lifelong journey of health and wellness.</span>
                                    </div>
                                </div>
                                <div className='mission-main'>
                                    <div>
                                        <span className='mission'>Mission</span>
                                        <hr/>
                                    </div>
                                    <div>
                                        <span className='mission-details'>To promote the physical, mental, and emotional benefits of Pilates, and to inspire individuals to incorporate this practice into their daily lives as a way to enhance their overall well-being.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {ctaBanner &&
                    <CTABanner ctaBanner={ctaBanner}/>
                    }
                </div>
            </div>
        )
    } else {
        return (
            <Loading/>
        );
    }
}
