import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import '../assets/css/header.css';
import { loadLimitedAnnouncements } from "../common/Common";
import { deleteUser, getUser } from "../auth/Auth";
import userProfile from "../assets/images/user.png";
import logo from "../assets/images/logo-light.svg";
import { postRequest } from "../routes/Routes";

function Header() {
    const [announcement, setAnnouncement] = useState(null),
        [user, setUser] = useState(undefined),
        [isOpen, setIsOpen] = useState(false),
        [loading, setLoading] = useState(false),
        navigate = useNavigate(),
        dropdownRef = useRef(null),
        dropdownRefMobile = useRef(null);

    useEffect(() => {
        getUser(false).then((data) => {
            setUser(data === undefined ? null : data);
        }).catch(() => {
            setUser(null);
        });
        loadLimitedAnnouncements(1).then((data) => {
            setAnnouncement(data.length > 0 ? data[0] : null);
        }).catch(() => null);
    }, []);

    useEffect(() => {
        const sidebar = document.getElementById("sidebar");
        const menuButton = document.getElementById("menu-button");
        const closeButton = document.getElementById("close-button");

        function openMenu() {
            sidebar.style.display = "flex";
            sidebar.style.animation = "sidebarIn 1s 0s forwards";
        }

        function closeMenu() {
            sidebar.style.animation = "sidebarOut 1s 0s forwards";
            setTimeout(() => {
                sidebar.style.display = "none";
            }, 500);
        }

        menuButton.addEventListener("click", openMenu);
        closeButton.addEventListener("click", closeMenu);
        const links = sidebar.querySelectorAll(".mobile-item-menu .header-link-close");
        links.forEach(link => {
            link.addEventListener("click", closeMenu);
        });

        return () => {
            menuButton.removeEventListener("click", openMenu);
            closeButton.removeEventListener("click", closeMenu);
            links.forEach(link => {
                link.removeEventListener("click", closeMenu);
            });
        };
    }, []);

    const [headerPadding, setHeaderPadding] = useState('48px');

    const handleNavigation = (path, event) => {
        event.preventDefault(); // Prevent default link behavior
        navigate(path); // Navigate to the new path
        closeMenu(); // Close the sidebar
    };
    
    const closeMenu = () => {
        const sidebar = document.getElementById("sidebar");
        if (sidebar) {
            sidebar.style.animation = "sidebarOut 1s 0s forwards";
            setTimeout(() => {
                sidebar.style.display = "none";
            }, 500);
        }
    };
    

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setHeaderPadding('48px');
            } else {
                setHeaderPadding(window.innerWidth <= 768 ? '48px' : '48px');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                (dropdownRef.current && !dropdownRef.current.contains(event.target)) &&
                (dropdownRefMobile.current && !dropdownRefMobile.current.contains(event.target))
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const handleLogout = () => {
        setLoading(true);
        setIsOpen(false);
        let values = {
            endpoint: '/api/logout',
            token: user.token,
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                deleteUser().then(() => {
                    window.location.href = '/login';
                });
            }
            setLoading(false);
        });
    };
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const classesDropdownRef = useRef(null); // Renamed to avoid conflicts
    const classesDropdownRefMobile = useRef(null);

    const toggleDropdown2 = () => {
        setDropdownOpen((prev) => !prev);
    };

    const handleItemClick = (e, path) => {
        handleNavigation(path, e); // Navigate
        setDropdownOpen(false); // Close dropdown after navigation
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (classesDropdownRef.current && !classesDropdownRef.current.contains(event.target) &&
                (classesDropdownRefMobile.current && !classesDropdownRefMobile.current.contains(event.target))) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <>
            {announcement &&
                <div className='yellow-class'>
                    {announcement.details.split(new RegExp("(?<=\ |^)" + announcement.link_text + "(?=\ |$)")).map((item, index) => (
                        <>
                            {item === "" ?
                                <a href={announcement.link} target="_blank" style={{ textDecoration: "underline" }}>
                                    {announcement.link_text}
                                </a>
                                : <>
                                    {index === 1 && announcement.details.split(announcement.link_text)[0] !== "" ?
                                        <>
                                            <a href={announcement.link} target="_blank"
                                                style={{ textDecoration: "underline" }}>
                                                {announcement.link_text}
                                            </a>
                                            <>{item}</>
                                        </>
                                        : <>{item}</>
                                    }
                                </>
                            }
                        </>
                    ))}
                </div>
            }
            <header style={{ paddingTop: headerPadding }}>
                <div className='container header-container' >
                    <Link className="logo-link" to="/">
                        <div className="logo-box">
                            <div className="logo">
                                <img src={logo} alt='Logo' />
                            </div>
                        </div>
                    </Link>

                    <nav >
                      <div className='main-wrapper-link' ref={classesDropdownRef}>
                        <div className="dropdown-container" >
                          <button className="header-link dropdown-toggle-new" onClick={toggleDropdown2}>
                              Classes
                              <svg width="24" height="24"  x="0"  y="0"  viewBox="0 0 128 128" enable-background="new 0 0 512 512">
                                <g>
                                  <path
                                    d="M64 88a3.988 3.988 0 0 1-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0L64 78.344l37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40A3.988 3.988 0 0 1 64 88z"
                                    fill="#fff"
                                    opacity="1"
                                  />
                                </g>
                              </svg>
                          </button>
                          {isDropdownOpen && (
                              <div className="dropdown-menu2" ref={classesDropdownRef}>
                                 <Link className="dropdown-item" onClick={(e) => handleItemClick(e, '/intensity-based')} to="/intensity-based">Intensity Based</Link>
                                 <Link className="dropdown-item" onClick={(e) => handleItemClick(e, '/time-based')} to="/time-based">Time Based</Link>
                              </div>
                          )}
                        </div>
                      </div>
                        <Link className="header-link" onClick={(e) => handleNavigation('/courses', e)} to="/courses">Courses</Link>
                        <Link className="header-link" onClick={(e) => handleNavigation('/contact-us', e)} to="/contact-us">Connect</Link>
                        <Link className="header-link" onClick={(e) => handleNavigation('/about-us', e)} to="/about-us">About</Link>
                        <Link className="header-link" onClick={(e) => handleNavigation('/how-to', e)} to="/how-to">How to</Link>
                        <Link className="header-link" onClick={(e) => handleNavigation('/price', e)} to="/price">Subscriptions</Link>
                        {user ?
                            <span className={`header-link login-signup ${user ? 'no-background' : ''}`} >
                                <div className='login-signup-photo-name' onClick={toggleDropdown}>
                                    <img src={user.photo ? user.photo : userProfile} alt='user photo' />
                                    <p>Hi, {user.first_name}

                                    </p>
                                    <svg width="24" height="24" x="0" y="0" viewBox="0 0 128 128" enable-background="new 0 0 512 512">
                                        <g>
                                            <path d="M64 88a3.988 3.988 0 0 1-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0L64 78.344l37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40A3.988 3.988 0 0 1 64 88z" fill="#fff" opacity="1" />
                                        </g>
                                    </svg>

                                    {/* <img src={downarr} alt="toggler" className='down-ico-hdr'/> */}
                                </div>
                                {isOpen && (
                                    <div ref={dropdownRef} className="dropdown-menu">
                                        <Link onClick={
                                            (e) => {handleNavigation('/profile', e);
                                            setIsOpen(false);}} to="/profile">
                                            <button className="dropdown-item">View Profile</button>
                                        </Link>
                                        <button className="dropdown-item" onClick={handleLogout}>
                                            {loading ?
                                                <span className="spinner-grow spinner-grow-sm" role="status"
                                                    aria-hidden="true" />
                                                : null
                                            }
                                            Logout
                                        </button>
                                    </div>
                                )}
                            </span>

                            : <Link className="header-link login-signup" onClick={(e) => handleNavigation('/login', e)} to="/login">Login / Signup</Link>
                        }
                    </nav>

                    <button className="menu-button" id="menu-button">
                        <i className="bi bi-list" />
                    </button>
                </div>
            </header>

            <div className="sidebar" id="sidebar">
                <div className="sidebar-header">
                    <span className="sidebar-title">Menu</span>
                    <button className="close-button" id="close-button">
                        <i className="bi bi-x-lg" />
                    </button>
                </div>
                <div className='mobile-item-menu'>
                  <div className='main-wrapper-link' >
                      <div className="dropdown-container" >
                        <button className="header-link  dropdown-toggle-new" onClick={toggleDropdown2}>
                            Classes
                            <svg width="24" height="24"  x="0"  y="0"  viewBox="0 0 128 128" enable-background="new 0 0 512 512">
                              <g>
                                <path
                                  d="M64 88a3.988 3.988 0 0 1-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0L64 78.344l37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40A3.988 3.988 0 0 1 64 88z"
                                  fill="#fff"
                                  opacity="1"
                                />
                              </g>
                            </svg>
                        </button>
                        {isDropdownOpen && (
                            <div  className="dropdown-menu2">
                                <Link className="dropdown-item header-link-close" onClick={(e) => handleItemClick(e, '/intensity-based')} to="/indensity-based">Intensity Based</Link>
                                <Link className="dropdown-item header-link-close" onClick={(e) => handleItemClick(e, '/time-based')} to="/time-based">Time Based</Link>
                            </div>
                        )}
                      </div>
                    </div>

                    <Link className="header-link header-link-close" onClick={(e) => handleNavigation('/courses', e)} to="/courses">Courses</Link>
                    <Link className="header-link header-link-close" onClick={(e) => handleNavigation('/contact-us', e)} to="/contact-us">Connect</Link>
                    <Link className="header-link header-link-close" onClick={(e) => handleNavigation('/about-us', e)} to="/about-us">About</Link>
                    <Link className="header-link header-link-close" onClick={(e) => handleNavigation('/how-to', e)} to="/how-to">How to</Link>
                    <Link className="header-link header-link-close" onClick={(e) => handleNavigation('/price', e)} to="/price">Subscriptions</Link>
                </div>
                {user ?
                    <div className='login-sgn-mobile'>
                        <div className='flx-br'>
                            <div className='login-signup-photo-name' onClick={toggleDropdown}>
                                <img src={user.photo ? user.photo : userProfile} alt='user photo' />
                                <p>Hi, {user.first_name}</p>
                                <svg width="24" height="24" x="0" y="0" viewBox="0 0 128 128" enable-background="new 0 0 512 512"><g><path d="M64 88a3.988 3.988 0 0 1-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0L64 78.344l37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40A3.988 3.988 0 0 1 64 88z" fill="#fff" opacity="1"></path></g></svg>
                            </div>
                            {isOpen && (
                                <div ref={dropdownRefMobile}  className="dropdown-menu">
                                    <Link onClick={(e) => handleNavigation('/profile', e)} to="/profile">
                                        <button className="dropdown-item" >View Profile</button>
                                    </Link>
                                    <button className="dropdown-item" onClick={handleLogout}>
                                        {loading ?
                                            <span className="spinner-grow spinner-grow-sm" role="status"
                                                aria-hidden="true" />
                                            : null
                                        }
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    : <div className='login-sgn-mobile'>
                        <div className='flx-br'>Don’t have an account? <Link onClick={(e) => handleNavigation('/signup', e)} to="/signup">Sign Up here</Link></div>
                        <div className='flx-br'>Already A member?<Link onClick={(e) => handleNavigation('/login', e)} to="/login"> Login here</Link></div>
                    </div>
                }
            </div>
        </>
    )
}

export default Header